/* eslint-disable no-mixed-operators */
import React, { CSSProperties, FC } from 'react';

type ThermostatProps = {
  diameter: number;
  ticks: number;
  minValue: number;
  maxValue: number;
  value: number | undefined;
  leaf: boolean;
  minRangeValue: number;
  maxRangeValue: number;
};

const getStyles = (props: ThermostatProps) => {
  return {
    dial: {
      userSelect: 'none',
    } as CSSProperties,
    circle: {
      fill: '#222',
      transition: 'fill 0.5s',
    } as CSSProperties,
    target: {
      fill: 'white',
      textAnchor: 'middle',
      alignmentBaseline: 'central',
      fontSize: 40,
      fontWeight: 'bold',
    } as CSSProperties,
    label: {
      fill: 'white',
      textAnchor: 'middle',
      alignmentBaseline: 'central',
      fontSize: '11px',
      fontWeight: 'bold',
    } as CSSProperties,
    leaf: {
      fill: '#ffa726',
      opacity: (props.leaf ? '1' : '0'),
      WebkitTransition: 'opacity 0.5s',
      transition: 'opacity 0.5s',
      pointerEvents: 'none',
    } as CSSProperties,
  };
};

type Point = [number, number];

const point = (x: number, y: number): Point => [x, y];
const pointsToPath = (points: Point[]) => [points.map(
  ([x, y], iPoint) => [(iPoint > 0 ? 'L' : 'M'), x, ' ', y].join(''),
).join(' '), 'Z'].join('');
const normalize = (value: number, min: number, max: number) => Math.min(Math.max(min, value), max);

const rotatePoint = (pt: Point, angle: number, origin: Point): Point => {
  const radians = angle * Math.PI / 180;
  const x = pt[0] - origin[0];
  const y = pt[1] - origin[1];

  return point(
    x * Math.cos(radians) - y * Math.sin(radians) + origin[0],
    x * Math.sin(radians) + y * Math.cos(radians) + origin[1],
  );
};

const rotatePoints = (points: Point[], angle: number, origin: Point) => points.map(
  (point) => rotatePoint(point, angle, origin),
);

const Thermostat: FC<ThermostatProps> = (props) => {
  // Local variables used for rendering.
  const diameter = props.diameter;
  const radius = diameter / 2;
  const ticksOuterRadius = diameter / 30;
  const ticksInnerRadius = diameter / 7;
  const tickDegrees = 300;
  const rangeValue = props.maxValue - props.minValue;

  // Determine the maximum and minimum values to display.
  const actualMinValue = Math.min(props.minRangeValue, props.maxRangeValue);
  const actualMaxValue = Math.max(props.minRangeValue, props.maxRangeValue);

  const min = normalize(Math.round((actualMinValue - props.minValue)
    / rangeValue * props.ticks), 0, props.ticks - 1);
  const max = normalize(Math.round((actualMaxValue - props.minValue)
    / rangeValue * props.ticks), 0, props.ticks - 1);
  const current = typeof props.value === 'undefined' ? null : (normalize(Math.round((props.value - props.minValue)
    / rangeValue * props.ticks), 0, props.ticks - 1));

  // Renders the degree ticks around the outside of the thermostat.
  const tickPoints = [
    [radius - 0.5, ticksOuterRadius],
    [radius + 0.5, ticksOuterRadius],
    [radius + 0.5, ticksInnerRadius],
    [radius - 0.5, ticksInnerRadius],
  ] as Point[];

  const tickPointsLarge = [
    [radius - 0.75, ticksOuterRadius],
    [radius + 0.75, ticksOuterRadius],
    [radius + 0.75, ticksInnerRadius + 8],
    [radius - 0.75, ticksInnerRadius + 8],
  ] as Point[];

  const theta = tickDegrees / props.ticks;
  const offsetDegrees = 180 - (360 - tickDegrees) / 2;
  const tickArray = [];

  for (let iTick = 0; iTick < props.ticks; iTick += 1) {
    const isLarge = iTick === min || iTick === max;
    const isActive = iTick >= min && iTick <= max;
    const isCurrent = current === iTick;
    const tickElement = React.createElement('path', {
      key: ['tick-', iTick].join(''),
      d: pointsToPath(
        rotatePoints(
          ((isLarge || isCurrent) ? tickPointsLarge : tickPoints),
          iTick * theta - offsetDegrees,
          [radius, radius] as Point,
        ),
      ),
      style: {
        fill: isCurrent ? 'orange' : (isActive ? 'rgba(255, 255, 255, 0.8)' : 'rgba(255, 255, 255, 0.3)'),
      },
    });
    tickArray.push(tickElement);
  }

  // Determines whether the ambient temperature label will be displayed
  // to the left or right of the tick range.
  const lblAmbientPosition: Point = [
    radius,
    ticksOuterRadius - (ticksOuterRadius - ticksInnerRadius) / 2,
  ];
  const peggedValue = normalize(props.minRangeValue, props.minValue, props.maxValue);
  const peggedValue2 = normalize(props.maxRangeValue, props.minValue, props.maxValue);

  let degs = tickDegrees * (peggedValue - props.minValue) / rangeValue - offsetDegrees;
  if (peggedValue > props.maxRangeValue) {
    degs += 10;
  } else {
    degs -= 10;
  }
  let degs2 = tickDegrees * (peggedValue2 - props.minValue) / rangeValue - offsetDegrees;
  if (peggedValue2 > props.maxRangeValue) {
    degs2 += 10;
  } else {
    degs2 -= 10;
  }
  const minLabelPosition = rotatePoint(lblAmbientPosition, 202, [radius, radius]);
  const maxLabelPosition = rotatePoint(lblAmbientPosition, 155, [radius, radius]);

  const ambientPosition = rotatePoint(lblAmbientPosition, degs, [radius, radius]);
  const ambient2Position = rotatePoint(lblAmbientPosition, degs2, [radius, radius]);

  // The styles change based on state.
  const styles = getStyles(props);

  return (
    <svg width={props.diameter} height={props.diameter} style={styles.dial}>
      <circle cx={radius} cy={radius} r={radius} style={styles.circle}></circle>
      <g>{tickArray}</g>
      <text x={radius} y={radius} style={styles.target}>
        {typeof props.value === 'number' ? <>{props.value?.toFixed(1)}&deg;</> : '-'}
      </text>
      <text x={minLabelPosition[0]} y={minLabelPosition[1]} style={styles.label}>
        {props.minValue}
      </text>
      <text x={maxLabelPosition[0]} y={maxLabelPosition[1]} style={styles.label}>
        {props.maxValue}
      </text>
      <text x={ambientPosition[0]} y={ambientPosition[1]} style={styles.label}>
        {props.minRangeValue.toFixed(1)}
      </text>
      <text x={ambient2Position[0]} y={ambient2Position[1]} style={styles.label}>
        {props.maxRangeValue.toFixed(1)}
      </text>
      <path d="m16 6-.44.55c-.42.52-.98.75-1.54.75C13 7.3 12 6.52 12 5.3V2S4 6 4 13c0 4.42 3.58 8 8 8s8-3.58 8-8c0-2.96-1.61-5.62-4-7zm-4 13c-1.1 0-2-.87-2-1.94 0-.51.2-.99.58-1.36L12 14.3l1.43 1.4c.37.37.57.85.57 1.36 0 1.07-.9 1.94-2 1.94zm3.96-1.5c.04-.36.22-1.89-1.13-3.22L12 11.5l-2.83 2.78C7.81 15.62 8 17.16 8.04 17.5 6.79 16.4 6 14.79 6 13c0-3.16 2.13-5.65 4.03-7.25.23 1.99 1.93 3.55 3.99 3.55.78 0 1.54-.23 2.18-.66C17.34 9.78 18 11.35 18 13c0 1.79-.79 3.4-2.04 4.5z" style={styles.leaf}
        transform={['translate(', radius - 10, ',', radius * 1.5 + 10, ')'].join('')}
      />
    </svg>
  );
};

export default Thermostat;
