import React, { FC, useMemo } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Warning } from '@mui/icons-material';
import { Page, useGoto } from '../../hooks/useGoto';
import { useConnection } from 'providers/ConnectionProvider';
import { BatteryIcon, BoilerIcon, DashboardIcon, HeaterIcon, ProcessorIcon, SettingsIcon, ConfigurationIcon, CameraIcon } from 'components/Icon';
import { useDataProvider } from 'providers';
import { StorageData } from '@smart-home/common';

const menuItems: { label: string; page: Page; icon: React.ElementType; direct?: boolean; showWarning?: (data: StorageData) => boolean }[] = [
  { label: 'Dashboard', page: 'dashboard', icon: DashboardIcon },
  { label: 'Servo', page: 'servo', icon: HeaterIcon },
  { label: 'Boiler', page: 'boiler', icon: BoilerIcon },
  { label: 'Power', page: 'power', icon: BatteryIcon, showWarning: (data) => data.UPS?.[0]?.value.STATUS !== 'ONLINE' },
  { label: 'Accessories', page: 'accessories', icon: ProcessorIcon, direct: true },
  { label: 'Setting', page: 'settings', icon: SettingsIcon },
  { label: 'Services', page: 'configuration', icon: ConfigurationIcon, direct: true },
  { label: 'Cameras', page: 'surveillance', icon: CameraIcon, showWarning: (data) => data.CAMERA?.some(x => x.value?.status !== 'online') },
  // { label: 'Service', page: 'service', icon: WifiTethering, direct: true },
];

const HomePage: FC = () => {
  const goto = useGoto();
  const connection = useConnection();
  const { data } = useDataProvider();
  const items = useMemo(() => menuItems.filter(x => !x.direct || connection.status === 'direct'), [connection.status]);

  return (
    <Grid container pt={2}>
      {items.map(({ icon: Icon, label, page, showWarning }) => (
        <Grid item key={page} xs={4} display="flex" justifyContent="center" alignItems="center">
          <Button sx={{ flexDirection: 'column', height: 100, width: '100%', position: 'relative' }} onClick={() => goto(page)}>
            <Icon fontSize="large" />
            {data && showWarning?.(data) && (
              <Warning sx={{ fontSize: 14, position: 'absolute', left: 'calc(50% + 10px)', top: 16 }} color="warning" />
            )}
            <Typography component="div" fontSize={13} fontWeight={500} mt={0.5}>
              {label}
            </Typography>
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default HomePage;
