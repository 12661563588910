/* eslint-disable camelcase */
import { FC, useCallback } from 'react';
import { useDataProvider } from 'providers';
import { Card, CardContent, Typography, Grid, Stack, Box, TypographyProps } from '@mui/material';
import Temperature from './components/Temperature';
import ThermostatControl from './components/Thermostat';
import { TempSensorValue, AccessoryDetailValue, TEMP_SENSOR, XIAOMI_TEMP_SENSOR } from '@smart-home/common';
import { Cabin, LocalFireDepartment, LocalFireDepartmentOutlined, Thermostat } from '@mui/icons-material';
import { XiaomiTempSensorValue } from '@smart-home/common/src/accessories/xiaomi-temp-sensor';
import { makeStyles } from '@mui/styles';

import homePicture from 'assets/home.svg';
import boilerPicture from 'assets/boiler.svg';
import Spinner from 'components/Spinner';
import BoilerStatistic from './components/BoilerStatistic';
import PowerMeter from './components/PowerMeter';
import { BatteryIcon, GarageIcon } from 'components/Icon';
import { useGoto } from 'hooks/useGoto';

type SensorValueProps = {
  label?: string;
  value?: number;
  valueProps?: TypographyProps;
} & TypographyProps;

const useStyles = makeStyles(() => ({
  home: {
    width: 148,
    height: 148,
    backgroundRepeat: 'no-repeat',
    position: 'relative',
  },
  boiler: {
    width: 400,
    height: 180,
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    '& > *': {
      position: 'absolute',
    },
  },
}), { name: 'Link' });

const SensorValue: FC<SensorValueProps> = ({ label, value, valueProps, ...props }) => {
  return (
    <Typography component="span" display="block" {...props}>
      {label ? `${label}:` : ''} <Temperature value={value} {...valueProps} />
    </Typography>
  );
};

const DashboardPage: FC = () => {
  const { data } = useDataProvider();
  const classes = useStyles();
  const goto = useGoto();

  const { boiler_data } = data?.EMS ?? {};
  const boiler = data?.BOILER?.[0];

  const getSensorValue = useCallback((id: string): AccessoryDetailValue<TempSensorValue> | undefined => {
    return data?.[TEMP_SENSOR]?.find(x => x.id === id) as AccessoryDetailValue<TempSensorValue>;
  }, [data]);

  const getZigbeeSensorValue = useCallback((id: string): AccessoryDetailValue<XiaomiTempSensorValue> | undefined => {
    return data?.[XIAOMI_TEMP_SENSOR]?.find(x => x.id === id) as AccessoryDetailValue<XiaomiTempSensorValue>;
  }, [data]);

  const boilerRelay = data?.SOLID_RELAY?.find(x => x.id === '15');
  const isBoilerEnabled = boilerRelay?.value === true;
  const lastBoilerUpdate = boilerRelay?.updatedAt;

  const relayTempTopic = data?.BOILER[0]?.value.tempSensorTopic.split('/')[1];

  const upsStatus = data?.UPS?.[0]?.value?.STATUS ?? 'N/A';

  const isLoading = data === null;

  return (
    <Grid container px={2} spacing={1}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Spinner active={isLoading} height={200} />
            {!isLoading && (
              <Grid container spacing={2}>
                <Grid item xs="auto">
                  <Box className={classes.home} style={{ backgroundImage: `url(${homePicture})` }} >
                    <SensorValue pl={14} pt={1} value={getSensorValue('0x28F15A979407032E')?.value} fontSize={20} />
                    <Stack sx={{ top: 36, left: 30, position: 'absolute', width: 80, textAlign: 'right' }}>
                      <Box textAlign="center">
                        <Thermostat fontSize="large" />
                      </Box>
                      <SensorValue
                        value={getZigbeeSensorValue('0xa4c138849950002b')?.value.temperature}
                        fontSize={30}
                        height={38}
                      />
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs alignSelf="end">
                  <Box sx={{ mb: 6, ml: 2 }} onClick={() => goto('power', { state: { backUrl: '/dashboard' } })}>
                    <BatteryIcon style={{ stroke: 'currentcolor', color: upsStatus === 'ONLINE' ? '#66bb6a' : '#ffa726' }} />
                    <Typography variant="caption" ml={0.7} mt={1.3} display="inline-block" sx={{ verticalAlign: 'top' }}>{upsStatus}</Typography>
                  </Box>
                  <Stack direction="row" spacing={3}>
                    <Box>
                      <Cabin fontSize="large" sx={{ mb: -1 }} />
                      <SensorValue value={getSensorValue('0x28943D49F66C3C3E')?.value} />
                    </Box>
                    <Box>
                      <GarageIcon style={{ marginBottom: -8 }} />
                      <SensorValue value={getZigbeeSensorValue('0xa4c13895cff9e6e7')?.value.temperature} />
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs alignSelf="end">
                </Grid>
                <Grid item xs={12}>
                  <BoilerStatistic
                    data={data?.metadata?.boilerStatistic?.data}
                    relay={boilerRelay}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.boiler} style={{ backgroundImage: `url(${boilerPicture})` }}>
                    <SensorValue value={getSensorValue('0x282BC295F0013CC5')?.value} sx={{ right: 168, top: 7, fontSize: 14 }} />
                    <SensorValue value={getSensorValue('0x28FE2348F60C3C39')?.value} sx={{ right: 168, top: 41, fontSize: 14 }} />

                    <SensorValue value={getSensorValue('0x28C01048F67A3C11')?.value} sx={{ right: 168, top: 115, fontSize: 14 }}/>
                    <SensorValue value={getSensorValue('0x285DDD48F6053C8A')?.value} sx={{ right: 168, top: 149, fontSize: 14 }}/>

                    <Stack direction="column" alignItems="center" sx={{ left: 110, top: 20 }} onClick={() => goto('boiler', { state: { backUrl: '/dashboard' } })}>
                      {isBoilerEnabled
                        ? <LocalFireDepartment color="warning" />
                        : <LocalFireDepartmentOutlined />}
                      <SensorValue value={boiler_data?.curflowtemp} sx={{ right: 74, top: 138, fontSize: 14 }}/>
                    </Stack>

                    <Stack direction="column" alignItems="center" sx={{ left: 20, top: 84 }}>
                      <Typography fontSize={14} fontWeight="medium">OFF</Typography>
                    </Stack>
                  </Box>
                </Grid>
                {boiler && <Grid item xs={12}>
                  <Box display="inline-block" ml={-1} pt={1.2} sx={{ verticalAlign: 'top' }}>
                    <ThermostatControl
                      diameter={180}
                      ticks={90}
                      minValue={15}
                      maxValue={25}
                      value={relayTempTopic ? getSensorValue(relayTempTopic)?.value : undefined}
                      leaf={isBoilerEnabled}
                      minRangeValue={boiler.value.maxTemp}
                      maxRangeValue={boiler.value.minTemp}
                    />
                    <Typography variant="caption" mt={0} component="p" textAlign="center">Thermostat</Typography>
                  </Box>
                  {boiler_data && (
                    <Stack display="inline-block" direction="row" sx={{ ml: 2 }}>
                      <Box width={60} display="inline-block">
                        <PowerMeter
                          width={60}
                          height={190}
                          ticks={50}
                          minValue={0}
                          maxValue={100}
                          value={boiler_data.curflowtemp}
                          minRangeValue={15}
                          maxRangeValue={boiler_data.selflowtemp}
                          valueFormatter={(v, isCurr) => isCurr ? `${v.toFixed(1)}°` : v.toFixed(0)}
                        />
                        <Typography variant="caption" mt={0} component="p">Flow</Typography>
                      </Box>
                      <Box width={60} display="inline-block">
                        <PowerMeter
                          width={60}
                          height={190}
                          ticks={50}
                          minValue={0}
                          maxValue={100}
                          value={boiler_data.curburnpow}
                          minRangeValue={boiler_data.burnminpower}
                          maxRangeValue={boiler_data.burnmaxpower}
                          valueFormatter={(v, isCurr) => isCurr ? `${v.toFixed(0)}%` : v.toFixed(0)}
                        />
                        <Typography variant="caption" mt={0} component="p">Power</Typography>
                      </Box>
                    </Stack>
                  )}
                </Grid>
                }
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DashboardPage;
